<template>
  <div>
    <NuxtLayout>
      <div class="pb-32">
        <div class="pt-5 md:pt-14">
          <div class="container space-y-8">
            <div class="border-gray-100 md:border-b md:pb-6">
              <h2 class="text-primary text-center">
                {{ errorMessage }}
              </h2>
            </div>

            <pre v-if="isDev">{{ error }}</pre>

            <div class="flex justify-center space-y-16 md:mx-auto md:max-w-[38rem]">
              <UiButton
                type="primary"
                @click="handleError"
              >
                Terug naar homepage
              </UiButton>
            </div>
          </div>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: {
    message: string
    statusCode: number
  },
}>();

const isDev = process.dev;

useSeoMeta({
  title: 'Jaarbeurs - Error',
});

function handleError() {
  clearError({redirect: '/'});
}

const errorMessage = computed(() => {
  if (props.error.statusCode === 404) {
    return 'Pagina niet gevonden';
  }

  return 'Er is iets misgegaan';
});
</script>
