<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <UiNotification position="top center" />
  </div>
</template>
<script setup lang="ts">
const route = useRoute();

useHead({
  title: () => route.meta.title,
});
</script>
