import { default as logindVgcvoxpuXMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/auth/login.vue?macro=true";
import { default as request_45passworduMRsYPZ8PZMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/auth/request-password.vue?macro=true";
import { default as _91token_93O7RafbsZirMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/auth/reset-password/[token].vue?macro=true";
import { default as _91token_93BXAVIeChRgMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/auth/setup-password/[token].vue?macro=true";
import { default as bekabeldigFCwnL51xMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/beheer/bekabeld.vue?macro=true";
import { default as importUYuSjoKRuEMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/beheer/switch-ports/import.vue?macro=true";
import { default as indexRLCrm7xSfqMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/beheer/switch-ports/index.vue?macro=true";
import { default as calculatordY9oVSGulSMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/calculator.vue?macro=true";
import { default as indexUtPwEkwyaUMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/index.vue?macro=true";
import { default as index7oaycefNhBMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/klanten/[slug]/index.vue?macro=true";
import { default as index6b1fZPQCsfMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/klanten/index.vue?macro=true";
import { default as indexRKu3o4GlkbMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/medewerkers/[uuid]/index.vue?macro=true";
import { default as createSMltV9e2nwMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/medewerkers/create.vue?macro=true";
import { default as indexASTSgRMZErMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/medewerkers/index.vue?macro=true";
import { default as indexQWkTVAwq76Meta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/orders/[slug]/index.vue?macro=true";
import { default as indexW9EBWlxVCPMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/profile/index.vue?macro=true";
import { default as statushwiA7o636BMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/status.vue?macro=true";
export default [
  {
    name: logindVgcvoxpuXMeta?.name ?? "auth-login",
    path: logindVgcvoxpuXMeta?.path ?? "/auth/login",
    meta: logindVgcvoxpuXMeta || {},
    alias: logindVgcvoxpuXMeta?.alias || [],
    redirect: logindVgcvoxpuXMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: request_45passworduMRsYPZ8PZMeta?.name ?? "auth-request-password",
    path: request_45passworduMRsYPZ8PZMeta?.path ?? "/auth/request-password",
    meta: request_45passworduMRsYPZ8PZMeta || {},
    alias: request_45passworduMRsYPZ8PZMeta?.alias || [],
    redirect: request_45passworduMRsYPZ8PZMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/auth/request-password.vue").then(m => m.default || m)
  },
  {
    name: _91token_93O7RafbsZirMeta?.name ?? "auth-reset-password-token",
    path: _91token_93O7RafbsZirMeta?.path ?? "/auth/reset-password/:token()",
    meta: _91token_93O7RafbsZirMeta || {},
    alias: _91token_93O7RafbsZirMeta?.alias || [],
    redirect: _91token_93O7RafbsZirMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93BXAVIeChRgMeta?.name ?? "auth-setup-password-token",
    path: _91token_93BXAVIeChRgMeta?.path ?? "/auth/setup-password/:token()",
    meta: _91token_93BXAVIeChRgMeta || {},
    alias: _91token_93BXAVIeChRgMeta?.alias || [],
    redirect: _91token_93BXAVIeChRgMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/auth/setup-password/[token].vue").then(m => m.default || m)
  },
  {
    name: bekabeldigFCwnL51xMeta?.name ?? "beheer-bekabeld",
    path: bekabeldigFCwnL51xMeta?.path ?? "/beheer/bekabeld",
    meta: bekabeldigFCwnL51xMeta || {},
    alias: bekabeldigFCwnL51xMeta?.alias || [],
    redirect: bekabeldigFCwnL51xMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/beheer/bekabeld.vue").then(m => m.default || m)
  },
  {
    name: importUYuSjoKRuEMeta?.name ?? "beheer-switch-ports-import",
    path: importUYuSjoKRuEMeta?.path ?? "/beheer/switch-ports/import",
    meta: importUYuSjoKRuEMeta || {},
    alias: importUYuSjoKRuEMeta?.alias || [],
    redirect: importUYuSjoKRuEMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/beheer/switch-ports/import.vue").then(m => m.default || m)
  },
  {
    name: indexRLCrm7xSfqMeta?.name ?? "beheer-switch-ports",
    path: indexRLCrm7xSfqMeta?.path ?? "/beheer/switch-ports",
    meta: indexRLCrm7xSfqMeta || {},
    alias: indexRLCrm7xSfqMeta?.alias || [],
    redirect: indexRLCrm7xSfqMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/beheer/switch-ports/index.vue").then(m => m.default || m)
  },
  {
    name: calculatordY9oVSGulSMeta?.name ?? "calculator",
    path: calculatordY9oVSGulSMeta?.path ?? "/calculator",
    meta: calculatordY9oVSGulSMeta || {},
    alias: calculatordY9oVSGulSMeta?.alias || [],
    redirect: calculatordY9oVSGulSMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/calculator.vue").then(m => m.default || m)
  },
  {
    name: indexUtPwEkwyaUMeta?.name ?? "index",
    path: indexUtPwEkwyaUMeta?.path ?? "/",
    meta: indexUtPwEkwyaUMeta || {},
    alias: indexUtPwEkwyaUMeta?.alias || [],
    redirect: indexUtPwEkwyaUMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index7oaycefNhBMeta?.name ?? "klanten-slug",
    path: index7oaycefNhBMeta?.path ?? "/klanten/:slug()",
    meta: index7oaycefNhBMeta || {},
    alias: index7oaycefNhBMeta?.alias || [],
    redirect: index7oaycefNhBMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/klanten/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index6b1fZPQCsfMeta?.name ?? "klanten",
    path: index6b1fZPQCsfMeta?.path ?? "/klanten",
    meta: index6b1fZPQCsfMeta || {},
    alias: index6b1fZPQCsfMeta?.alias || [],
    redirect: index6b1fZPQCsfMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/klanten/index.vue").then(m => m.default || m)
  },
  {
    name: indexRKu3o4GlkbMeta?.name ?? "medewerkers-uuid",
    path: indexRKu3o4GlkbMeta?.path ?? "/medewerkers/:uuid()",
    meta: indexRKu3o4GlkbMeta || {},
    alias: indexRKu3o4GlkbMeta?.alias || [],
    redirect: indexRKu3o4GlkbMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/medewerkers/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: createSMltV9e2nwMeta?.name ?? "medewerkers-create",
    path: createSMltV9e2nwMeta?.path ?? "/medewerkers/create",
    meta: createSMltV9e2nwMeta || {},
    alias: createSMltV9e2nwMeta?.alias || [],
    redirect: createSMltV9e2nwMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/medewerkers/create.vue").then(m => m.default || m)
  },
  {
    name: indexASTSgRMZErMeta?.name ?? "medewerkers",
    path: indexASTSgRMZErMeta?.path ?? "/medewerkers",
    meta: indexASTSgRMZErMeta || {},
    alias: indexASTSgRMZErMeta?.alias || [],
    redirect: indexASTSgRMZErMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/medewerkers/index.vue").then(m => m.default || m)
  },
  {
    name: indexQWkTVAwq76Meta?.name ?? "orders-slug",
    path: indexQWkTVAwq76Meta?.path ?? "/orders/:slug()",
    meta: indexQWkTVAwq76Meta || {},
    alias: indexQWkTVAwq76Meta?.alias || [],
    redirect: indexQWkTVAwq76Meta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/orders/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexW9EBWlxVCPMeta?.name ?? "profile",
    path: indexW9EBWlxVCPMeta?.path ?? "/profile",
    meta: indexW9EBWlxVCPMeta || {},
    alias: indexW9EBWlxVCPMeta?.alias || [],
    redirect: indexW9EBWlxVCPMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: statushwiA7o636BMeta?.name ?? "status",
    path: statushwiA7o636BMeta?.path ?? "/status",
    meta: statushwiA7o636BMeta || {},
    alias: statushwiA7o636BMeta?.alias || [],
    redirect: statushwiA7o636BMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/management/pages/status.vue").then(m => m.default || m)
  }
]